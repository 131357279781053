import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import Cookies from 'js-cookie';
import { API_BASE_URL, MEDIA_BASE_URL, BASE_URL } from "../../config/config";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";


function MainHeader () {
const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  
  const [isOpencall, setIsOpencall] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsOpen(true); // Reset isOpen after the animation completes
    }, 100); // Adjust the delay as needed to match the animation duration
  };




  const [sidebarVisible, setSidebarVisible] = useState(false);
  // const handleShowHideSidebarClick = () => {
  //   if (sidebarVisible) {
  //     document.body.classList.add('slideclass');
  //     document.getElementById('chat-side1').style.width = '0%';
  //     document.getElementById('main-1').style.width = '100%';
  //   } else {
  //     document.body.classList.remove('slideclass');
  //     document.getElementById('chat-side1').style.width = '30%';
  //     document.getElementById('main-1').style.width = '70%';
  //   }
  //   setSidebarVisible(!sidebarVisible);
  // };
  const [isFullScreen, setIsFullScreen] = useState(
    document.fullScreenElement ||
    document.mozFullScreen ||
    document.webkitIsFullScreen
  );

  const toggleFullScreen = () => {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  };
  const [showDiv, setShowDiv] = useState(false);

  const toggleDiv = () => {
    setShowDiv(!showDiv);
  };

  const closeDiv = () => {
    setShowDiv(false);
  };
  useEffect(() => {
    //console.log('UseEffeCT Main Header1');
    fetchcompanydetails();
    const handleFullScreenChange = () => {
      setIsFullScreen(
        document.fullScreenElement ||
        document.mozFullScreen ||
        document.webkitIsFullScreen
      );      
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
    };
   
  }, []);

  /////////////////////////////////////////////////// Get Company Details /////////////////////////
  const [companydetails, setCompanyDetails] = useState([]);
async function fetchcompanydetails() {
  try {    
    const response = await fetch(`${API_BASE_URL}/api/getCompany?registrationid=${Cookies.get("registrationid")}`, {
      method: 'GET',
      headers: {
        "Authorization":Cookies.get("access_token"),
        'Content-Type': 'application/json; charset=utf-8'       
      },
      // body: JSON.stringify({
      //   registrationid: Cookies.get("registrationid")       
      // })
    });
    
    const data = await response.json();
    setCompanyDetails(data.d);
    // console.log("mainheader");
    // console.log(data.d);

  } catch (error) {
    console.log("Test Error");
  }
}

/////////////////////////////////////////////  Notification Invoice /////////////////////////////
const [notificationinvoice, setNotificationInvoice] = useState([]);
async function fetchnotificationinvoice() {
  try {
 
    const response = await fetch(`${API_BASE_URL}/customer/NotificationInvoice?registrationid=${Cookies.get("registrationid")}`, {
      method: 'GET',
      headers: {
        "Authorization":Cookies.get("access_token"),
        'Content-Type': 'application/json; charset=utf-8'       
      },
      // body: JSON.stringify({
      //   registrationid: Cookies.get("registrationid")       
      // })
    });
    
    const data = await response.json();
    setNotificationInvoice((notificationinvoice)=>[...notificationinvoice, data.d]);

  } catch (error) {
    console.log("Test Error");
  }
}

/////////////////////////////////////////////  Notification Estimate /////////////////////////////
async function fetchnotificationestimate() {
  try {
    const response = await fetch(`${API_BASE_URL}/customer/NotificationEstimate?registrationid=${Cookies.get("registrationid")}`, {
      method: 'GET',
      headers: {
        "Authorization":Cookies.get("access_token"),
        'Content-Type': 'application/json; charset=utf-8'       
      },
      // body: JSON.stringify({
      //   registrationid: Cookies.get("registrationid")       
      // })
    });
    

    const data = await response.json();
    let datas=data.d;
    //setNotificationInvoice(...notificationinvoice, data.d);
    setNotificationInvoice((notificationinvoice)=>[...notificationinvoice, data.d]);

  } catch (error) {
    console.log("Test Error");
  }
}
/////////////////////////////////////////////  Notification Product /////////////////////////////
async function fetchnotificationproduct() {
  try {
    const response = await fetch(`${API_BASE_URL}/customer/NotificationProduct?registrationid=${Cookies.get("registrationid")}`, {
      method: 'GET',
      headers: {
        "Authorization":Cookies.get("access_token"),
        'Content-Type': 'application/json; charset=utf-8'       
      },
      // body: JSON.stringify({
      //   registrationid: Cookies.get("registrationid")       
      // })
    });
    
    const data = await response.json();
    //let datas=data.d;
    //setNotificationInvoice(...notificationinvoice, data.d);
    setNotificationInvoice((notificationinvoice)=>[...notificationinvoice, data.d]);
  } catch (error) {
    console.log("Test Error");
  }
}

////////////////////////////////////////  fetchdashboardmessage //////////////////////////////
const [dashboardmessage, setDashboardMessage] = useState([]);
async function fetchdashboardmessage() {
  try {
    const response = await fetch(`${API_BASE_URL}/customer/DashboardMessageReact?registrationid=${Cookies.get("registrationid")}`, {
      method: 'GET',
      headers: {
        "Authorization":Cookies.get("access_token"),
        'Content-Type': 'application/json; charset=utf-8'       
      },
      // body: JSON.stringify({
      //   registrationid: Cookies.get("registrationid")       
      // })
    });
    
    const data = await response.json();
    setDashboardMessage(data.d);
  } catch (error) {
    console.log("Test Error");
  }
}

//////////////////////////////////////////// handleunitvatinvoiceinterest ////////////////////////////////
const handleunitvatinvoiceinterest=(obj)=>{
window.unitvatinvoiceinterest(obj);
} 

////////////////////////////////handlepayrollsetting //////////////////////////////////
const handlepayrollsetting =(type) =>{
  if (type == "salarytype")
    window.location.href="PayrollSetting?type=salarytype";
else if (type == "deduction")
  window.location.href="PayrollSetting?type=deduction";
else
window.location.href="PayrollSetting?type=taxfree";

 // window.payrollsetting(type);
}


useEffect(() => {
  
  async function loadnotification(){
   try{
    //console.log('UseEffeCT Main Header');
await fetchnotificationinvoice();
//await fetchnotificationestimate();
//await fetchnotificationproduct();
await  fetchdashboardmessage();
setIsOpencall(true);
   }catch (error){
    console.log(error);
   }
}
if(!isOpencall)
loadnotification();
},[]);

  let publicUrl = process.env.PUBLIC_URL + '/'
    return (
        <>

{/* //////////////////////////////////////////  Modal for Settings //////////////////////// */}
<div className="modal fade transfer-amount" id="dashboardsettingmodal" tabIndex="-1" role="dialog" aria-hidden="true">
              <div className={`modal-dialog ${isOpen ? 'rollIn' : 'rollOut'} animated  modal-xl modal-dialog-centered`}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">{t("Setting")}</h4>
                    <button className="btn-close" onClick={handleClose} type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body hcj">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="card setting-card p-20">
                            <h5>{t("InvoiceSettings")}</h5>
                            <Link className="setingsd" to="#" onClick={handleunitvatinvoiceinterest.bind(this,'unit')} title="Add Unit">{t("AddUnit")}</Link> 
                            <Link className="setingsd" to="#" onClick={handleunitvatinvoiceinterest.bind(this,'vat')} title="Add VAT Percentage">{t("VAT%")}</Link> 
                            <Link className="setingsd" to="#" onClick={handleunitvatinvoiceinterest.bind(this,'invoice')} title="Add Invoice Type">{t("PaymentTerms")}</Link> 
                            <Link className="setingsd" to="#" onClick={handleunitvatinvoiceinterest.bind(this,'late')} title="Add Late Interest">{t("LateInterest/Fee")}</Link> 
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="card setting-card p-20">
                            <h5>{t("ACCOUNTSETTINGS")}</h5>
                            <Link className="setingsd" to="/MyWallet" title="My Wallet">{t("MyWallet")}</Link> 
                            <Link className="setingsd" to="/UserSetting" title="User Setting">{t("UserSetting")}</Link>
                            <Link className="setingsd" to="/TradeName" title="Trade name">{t("Tradename")}</Link>
                            <Link className="setingsd" to="/BankDetails" title="Bank Account">{t("BankAccount")}</Link>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="card setting-card p-20">
                            <h5>{t("TEMPLATESETTINGS")}</h5>
                            <Link className="setingsd" to="/InvoiceTemplateSetting" title="Invoice Template">{t("InvoiceTemplate")}</Link>
                            <Link className="setingsd" to="/EstTempSetting" title="Estimate Template">{t("EstimateTemplate")}</Link>
                            <Link className="setingsd" to="/ReminderTemplates" title="Reminder Template">{t("ReminderTemplate")}</Link>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-md-4">
                          <div className="card setting-card p-20">
                            <h5>{t("REMINDER SETTINGS")}</h5>
                            <Link className="setingsd" to="/AutoEmailSetting"> {t("EmailReminderSetting")}</Link>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="card setting-card p-20">
                            <h5>{t("PAYROLLSETTINGS")}</h5>
                            <Link className="setingsd" to="#" onClick={handlepayrollsetting.bind(this,"salarytype")} title="Salary Type">{t("SalaryType")}</Link>
                            <Link className="setingsd" to="#" onClick={handlepayrollsetting.bind(this,"deduction")} title="Deduction Type">{t("DeductionType")}</Link>
                            <Link className="setingsd" to="#" onClick={handlepayrollsetting.bind(this,"taxfree")} title="Tax Free Benefit">{t("TaxFreeBenefit")}</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



{/* //////////////////////////////////// Page Start ///////////////// */}

          <div className="page-header">
            <div className="header-wrapper row m-0">
              <form className="form-inline search-full col" action="#" method="get">
                <div className="form-group w-100">
                  <div className="Typeahead Typeahead--twitterUsers">
                    <div className="u-posRelative">
                      <input className="demo-input Typeahead-input form-control-plaintext w-100" type="text" placeholder="Search Here .." name="q" title="" autoFocus/>
                      <div className="spinner-border Typeahead-spinner" role="status"><span className="sr-only">Loading...</span></div><i className="close-search" data-feather="x"></i>
                    </div>
                    <div className="Typeahead-menu"></div>
                  </div>
                </div>
              </form>
              <div className="header-logo-wrapper col-auto p-0">
                <div className="logo-wrapper"><Link to="/dashboard"><img className="img-fluid for-light logo1" src={publicUrl+"assets/images/logo-zwato.png"} alt=""/><img className="img-fluid for-dark logo1" src={publicUrl+"assets/images/logo-zwato.png"} alt=""/></Link></div>
                <div className="toggle-sidebar"><i className="status_toggle middle sidebar-toggle fa-solid fa-bars-staggered"></i></div>
              </div>
              <div className="left-header col horizontal-wrapper ps-0"><i className=""></i>
                <div className="input-group">     
                  <input className="form-control" type="text" id="searchtop" placeholder="Search Here........"/><span className="input-group-text mobile-search"><i className="fa-solid fa-magnifying-glass"></i></span>
                </div>
              </div>
              <div className="nav-right col-6 pull-right right-header p-0">
                <ul className="nav-menus">   
                  <li className="language-nav lngselecter">
                    <div className="translate_wrapper">
                      <div className="current_lang">
                      <LanguageSelector />
                        {/* <div className="lang"><i className="fi fi-us"></i><span className="lang-txt">EN </span></div> */}
                      </div>
                      {/* <div className="more_lang">
                        <div className="lang selected" data-value="en"><i className="fi fi-us"></i><span className="lang-txt">English<span> (US)</span></span></div>
                        <div className="lang" data-value="fi"><i className="fi fi-fi"></i><span className="lang-txt">Finish</span></div>
                      </div> */}
                    </div>
                  </li>
                  <li>
                    <Link className="mode" to="/Deleteditems"><i className="fa-sharp fa-solid fa-trash f-16 btn02"></i></Link>
                  </li>
                  <li>
                    <div className="mode" title="Settings" data-bs-toggle="modal" data-bs-target=".transfer-amount"><i className="fa-sharp fa-solid fa-gears f-16 btn02"></i></div>
                  </li>
                  <li>
                    {/* <button type="button" className="off" id="showhidesidebar" value="Show" onClick={handleShowHideSidebarClick}>expand</button> */}
                    <button type="button" className="off" style={{position:"relative"}} data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample">                    
                    <i className="fas fa-comment" data-bs-target="#offcanvasExample" data-bs-toggle="offcanvas" ></i>
                    <div className="pulse-css"></div>
                    </button>
                  </li>
                  
                  <li className="onhover-dropdown">
                  {/* <span className="badge rounded-pill badge-primary">4 </span> */}
                    <div className="notification-box" title="Notifications"><i className="fa-sharp fa-regular fa-bell f-16"></i></div>
                    {notificationinvoice && (<>
                    <ul className="notification-dropdown onhover-show-div" dangerouslySetInnerHTML={{ __html: notificationinvoice}}>                     
                    </ul>
                    </>)}
                  </li>
                  <li className="onhover-dropdown" title="Message"><i className="fa-sharp fa-solid fa-envelope f-16"></i>
                    <ul className="chat-dropdown onhover-show-div" dangerouslySetInnerHTML={{ __html: dashboardmessage}}>
                      
                    </ul>
                  </li>
                  <li className="maximize"><Link className="text-dark" title="Maximize" to="#!" onClick={toggleFullScreen}><i className="fa-regular fa-expand f-16"></i></Link></li>
                  <li className="profile-nav onhover-dropdown p-0 me-0">
                    <div className="d-flex profile-media"><img className="b-r-50" src={companydetails.photo && companydetails.photo =="" ? publicUrl+"assets/images/per.png" : companydetails.photo } alt=""/>
                      <div className="flex-grow-1"><span>{companydetails.companyname}</span>
                        <p className="mb-0 font-roboto">{companydetails.registertype} <i className="middle fa fa-angle-down"></i></p>
                      </div>
                    </div>
                    <ul className="profile-dropdown onhover-show-div">
                      <li><Link to="/MyAccount"><i className="fa-sharp fa-solid fa-user"></i> <span> {t("MyAccount")} </span></Link></li>
                      {/* <li><Link to="#"><i className="fa-solid fa-mailbox"></i> <span>Inbox</span></Link></li>
                      <li><Link to="#"><i className="fa-sharp fa-solid fa-list-check"></i> <span>Taskboard</span></Link></li> */}
                      <li><Link to="/SignOut"><i className="fa-solid fa-right-to-bracket"></i> <span>{t("SignOut")}</span></Link></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
    )
};

export default MainHeader;