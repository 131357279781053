import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useCookies } from 'react-cookie';
import Loader1 from '../global-component/Loader1';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import swal from "sweetalert2";
import InputGroup from 'react-bootstrap/InputGroup';
import jQuery from "jquery";
import $ from "jquery";
import axios from 'axios';
import Cookies from 'js-cookie';
import { API_BASE_URL, MEDIA_BASE_URL, BASE_URL } from "../../config/config";





function VerifyEmail () {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let email = params.get('ecode');  

    useEffect(() => {
        $("#emailverified").hide(); 


        try {    
            const response = fetch(`${API_BASE_URL}/customer/EmailVerification`, {
              method: 'POST',
              headers: {                
                'Content-Type': 'application/json; charset=utf-8'       
              },
               body: JSON.stringify({
                 email: email,       
               })
            });
            const data = response.json();  
            
           
           
          } catch (error) {
            // Handle errors here
            console.log("Error in Get Company Details");
          }
       
      }, []);


    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
<>
{/* <br /><br /><br /><br /> */}
{/* <div className="container h-100 align-items-center justify-content-center" id="emailverified">                 
            <div className="justify-content-center align-items-center" style={{background:"#ffffff"}}>
                <div className="row login-sec reg12 animate__animated animate__zoomIn" data-animation="zoomIn" style={{background:"#ffffff"}}>                               
                     <div className="col-md-12 text-center" style={{borderBottom:"2px solid #efdafa;", marginBottom:"20px", paddingBottom:"20px"}}>
                         <img src="https://media.zwato.com/images/softa.png" style={{display:"inline-block"}} />
                     </div>
                 <div className="col-md-4">
                     <img src="https://media.zwato.com/images/inbox-icon-animation.gif" style={{width:"100%"}} />
                     </div>
             <div className="col-md-8 text-left">              
                <h3 className="" style={{fontSize:"30px", fontWeight:"700", marginBottom:"20px"}}>Your Email has been Verified successfully!</h3>
                   <p style={{fontSize:"1.2rem", lineHeight:"1.5"}}>Now you can create Invoices & Estimates and send to customer by email for free.</p>                        
                   <br />
                 <p><a href="/signin" className="btn btn-primary">Click here to login</a></p>
                 </div>
                  </div>
                 </div>
                   </div> */}

                   <div className="page-wrapper compact-wrapper" id="pageWrapper">
  <div className="container-fluid">
    <br/>
    <br/>
    <br/>
    <br/>
    <div className="row justify-content-center">
      <div className="col-lg-6 text-center">
        <div className="card mt-5 mb-1">
          <div className="card-body p-3">
            <a href="/"><img src="https://app.zwato.com/assets/images/logo-zwato.png" alt="logo" style={{maxWidth:"150px"}} /></a>
          </div>
        </div>
      </div>
    </div>
    <div className="row g-1 justify-content-center">
      <div className="col-lg-2 text-center">
        <div className="card">
          <div className="card-body">
            <img src="https://media.zwato.com/images/inbox-icon-animation.gif" className="img-fluid" style={{maxWidth:"175px"}} />
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card">
          <div className="card-body">
            <h3 className="">Your email has been verified successfully!</h3>
            <p>Now you can create Invoices &amp; Estimates and send to customer by email for free.</p>
            <p><a href="/signin" className="btn btn-primary">Click here to login</a></p>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
               
               

</>
    );
}
export  default VerifyEmail;
