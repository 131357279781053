import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap"; // Assuming you're using react-bootstrap for Form.Select
import Cookies from 'js-cookie';
import { API_BASE_URL,MEDIA_BASE_URL } from '../../config/config';
import $ from "jquery";
import { useCookies } from 'react-cookie';


// const languages=[
//     {code:"en", lang:"English"},
//     {code:"fr", lang:"French"},
//     {code:"hi", lang:"Hindi"},
//     {code:"fi", lang:"Finish"},
//     {code:"ar", lang:"Arabic"}
// ];


const LanguageSelector =(props) =>{
      
    const { i18n } = useTranslation(); 
    const [selectedOption, setSelectedOption] = useState(i18n.language);
const [languages, srtLanguages] = useState([]);
   async function getActiveLanguages(){
        try {   
              const response = await fetch(`${API_BASE_URL}/language/GetActivelanguagesitechanges`, {
                method: 'GET',
                headers: {
                  //Authorization: Cookies.get("access_token"),
                  'Content-Type': 'application/json; charset=utf-8'
                },              
            });
            
            const data = await response.json();           
            srtLanguages(data.d);
          
          } catch (error) {
            // Handle errors here
            console.log("Error on get Language data");
          }
    }


    const changeLanguage = (event) =>{
        i18n.changeLanguage(event.target.value);  
        setSelectedOption(event.target.value);
        var skillsSelect = document.getElementById("selectlng");
        var selectedText = skillsSelect.options[skillsSelect.selectedIndex].text; 
        let expires = new Date();
        expires.setTime(expires.getTime() + (86000 * 1000));         
        setCookie('sitelanguage', `${selectedText}`, { path: '/',  expires});  
        localStorage.setItem('sitelanguage', selectedText);
        console.log("changes");
       // console.log(event.target.options[event.target.selectedIndex].text);
       // console.log(event.target.options[event.target.selectedIndex].getAttribute('ltext'));
    };
    
    useEffect(() =>{        
        document.body.dir=i18n.dir();
    },[i18n, i18n.language])

const [cookies, setCookie] = useCookies([]);
    useEffect(() =>{    
        async function data(){       
      await  getActiveLanguages();
        setTimeout(function(){            
       
        var skillsSelectt = document.getElementById("selectlng");
        if(skillsSelectt)
        var selectedTextt = skillsSelectt.options[skillsSelectt.selectedIndex].text; 
        let expires = new Date();
        expires.setTime(expires.getTime() + (86000 * 1000));      
        setCookie('sitelanguage', selectedTextt);  
        localStorage.setItem('sitelanguage', selectedTextt);   
    }, 300);
}
data();

    },[])

    useEffect(() =>{      
        var skillsSelect = document.getElementById("selectlng");
        var selectedText = skillsSelect.options[skillsSelect.selectedIndex].text; 
        let expires = new Date();
        expires.setTime(expires.getTime() + (86000 * 1000));         
        setCookie('sitelanguage', selectedText);
        localStorage.setItem('sitelanguage', selectedText); 
    },[selectedOption])

return (
    <Form.Select onChange={changeLanguage} value={selectedOption} aria-label="" id="selectlng" className="">
        <option>Select Language</option>
    {languages && languages.map((lng, index) => (
        <option key={index} value={lng.ISO2} ltext={lng.LanguageName}>
        {lng.LanguageName}
        </option>
    ))}
    </Form.Select> 
       // <div className="lang" ><i className={`fi fi-${lng.ISO2}`}></i><span className="lang-txt"> {lng.LanguageName} </span></div>
)
}
export default LanguageSelector;