import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useCookies } from 'react-cookie';
import Loader1 from '../global-component/Loader1';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import swal from "sweetalert2";
import InputGroup from 'react-bootstrap/InputGroup';
import jQuery from "jquery";
import $ from "jquery";
import { GoogleLogin } from '@react-oauth/google';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { API_BASE_URL, MEDIA_BASE_URL, BASE_URL } from '../../config/config';
import Cookies from 'js-cookie';
import { useTranslation } from "react-i18next";
import LanguageSelector from "../global-component/LanguageSelector";

import { isMobile, isBrowser, deviceType, isTablet, isDesktop, browserName, isIOS,deviceDetect  } from 'react-device-detect';


function SignIn() {
  const {t} = useTranslation();
  const deviceInfo = deviceDetect();
  const { device } = deviceDetect();
  const userAgent = navigator.userAgent;
// Example regular expression to find device name
const match = userAgent.match(/\((.*?)\)/);
let deviceName = '';

if (match && match.length > 1) {
    const parts = match[1].split(';');
    if (parts.length > 0) {
      if(isMobile)
        deviceName = parts[1].trim();
      else
      deviceName = parts[0].trim();
    }
}
useEffect(() => {
window.loadbaseurl(API_BASE_URL, MEDIA_BASE_URL, BASE_URL, t);
});

  const [key, setKey] = useState('company-details');
  const [isOpen, setIsOpen] = useState(true);
    const handleClose = () => {
      setIsOpen(false);
      setTimeout(() => {
        setIsOpen(true); // Reset isOpen after the animation completes
      }, 100); // Adjust the delay as needed to match the animation duration
    };


  const [loading, setLoading] = useState(false);


    const defaultFormFields = {
        registertype:'',
        username: '',
        password: '',
        keepmesign:false,
      };
      const [cookies, setCookie] = useCookies(['access_token', 'refresh_token']);
      const [formFields, setFormFields] = useState(defaultFormFields);
      const {registertype, username, password, keepmesign } = formFields;
      const [isChecked, setIsChecked] = useState(false);

      const [showPassword, setShowPassword] = useState(false);      
      const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };

      // const handleverificationemail=(event) =>{
      //    alert("test");
      // }
      // function getverificationemial(){
      //   alert("test");
      // }

      const handleChange = (event) => {
        const { name, value } = event.target;        
        setFormFields({ ...formFields, [name]: value });
      };

      const handleChangetype = (event) => {
        setIsChecked(event.target.checked);
        setFormFields({...formFields, [event.target.name]: event.target.checked})
    };

      const handlesubmit = async (event) => {
        event.preventDefault();
        
        setLoading(true);
       // console.log(formFields);

    //    if(formFields.registertype===""){
    //     swal.fire({
    //       title: '<span style="color:#f27474">Opps!</span>',
    //       html: "Please select login type! <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
    //       type: 'error',
    // icon: "error",
    //       showCloseButton: true,
    //       showCancelButton: false,
    //       showConfirmButton: false
    //   });
       
    //         setLoading(false);
    //         return;
    //    }
       if(formFields.username==="" && formFields.password===""){
        swal.fire({
          title: `<span style="color:#f27474">${t("Opps")}!</span>`,
          html: `${t("Pleaseenterusernameorpassword!")} <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>${t("OK")}</a>`,
          type: 'error',
    icon: "error",
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: false
      });
     
            setLoading(false);
            return;
       }


        const axios = require('axios');

        // let datas = JSON.stringify({
        //   "logindata": {           
        //     "username": formFields.username,
        //     "password": formFields.password,
        //     "registertype": formFields.registertype,
        //     "keepmesign": formFields.keepmesign 
        //   }
        // });
        let datas = JSON.stringify({                  
            "username": formFields.username,
            "password": formFields.password,
            "registertype": formFields.registertype,
            "keepmesign": formFields.keepmesign          
        });
       // console.log(datas);
        let config = {
          method: 'post',
          maxBodyLength: Infinity,

          headers: { 
            'Content-Type': 'application/json'
          },
          body : datas
        };
        //"url": "/userRegistration/ReactLogin",
        fetch(`${API_BASE_URL}/userRegistration/ReactLogin`, config)
        .then(response => {
            if (!response.ok) {
             // throw new Error('Network response was not ok');
            }
            return response.json(); // Extract the response body as JSON
          })
          .then(data => {           
            if (data.d === 'usernotfound') {
              // Handle the case when user is not found
              swal.fire({
                title: `<span style="color:#f27474">${t("Opps")}!</span>`,
                html: `${t("Usernameorpasswordnotmatched.Pleasecheck!")} <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>${t("OK")}</a>`,
                type: 'error',
                icon: "error",
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false
            });
              
                setLoading(false);
              //console.log('User not found');
            }
            else if(data.d === 'usernotverified'){
              swal.fire({
                title: `<span style="color:#f27474">${t("Opps")}!</span>`,
                html: `${t("verifyemailfirst")} <a href='javascript:void(0)' onClick=getverificationemial()><strong>${t("ClickHere")}</strong></a> ${t("forgetverificationemail")}.<br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>${t("OK")}</a>`,
                type: 'error',
                icon: "error",
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false
            });
              
                setLoading(false);
               // console.log('User not Verified');
            }
            else if(data.d ==='usernotregister'){
              swal.fire({
                title: `<span style="color:#f27474">${t("Opps")}!</span>`,
                html: `${t("emailnotregistrefirst")} <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>${t("OK")}</a>`,
                type: 'error',
                icon: "error",
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false
            });
              
                setLoading(false);
            }
            else if(data.d=="passwordnotmatched"){
              swal.fire({
                title: `<span style="color:#f27474">${t("Opps")}!</span>`,
                html: `${t("passwordnotmatched")} <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>${t("OK")}</a>`,
                type: 'error',
                icon: "error",
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false
            });
            setLoading(false);
            }
            else {
              let expires = new Date()
              expires.setTime(expires.getTime() + (86000 * 1000));         
              setCookie('access_token', `Bearer ${data.access_token}`, { path: '/',  expires});     
                fetchlogindetails(data.d, data.access_token, data.msg);                
              // Process the user data if found;
             // console.log('User data:', data);
            }
          })      
        .catch((error) => {
         // console.log("Test Error-"+error);
         swal.fire({
          title: `<span style="color:#f27474">${t("Opps")}!</span>`,
          html: `${t("updationprogress")}.<br /><br /><a href='javascript:swal.close()' class='btn btn-primary btn-sm'>${t("OK")}</a>`,
          type: 'error',
          icon: "error",
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: false
      });     
        //  alert(error +" - Please check internet connection or API");
         // console.log(error);
          
          setLoading(false);
          return;
        });
      }

/////////////////////////////////////////// handleresetpassword /////////////////////////////
const handleresetpassword =() =>{
  let usernametxt=$("#txtforgotpassword").val();

  if(usernametxt=="")
    {
      swal.fire({
        title: `<span style="color:#f27474">${t("Opps")}!</span>`,
        html: `${t("Pleaseenteremailorusername")}<br /><br /><a href='javascript:swal.close()' class='btn btn-primary btn-sm'>${t("OK")}</a>`,
        type: 'error',
        icon: "error",
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false
    });
    return;
    }

   var regex = /^(([^<>[\]\\.,;:\s@"]+(\.[^<>[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   if (!regex.test(usernametxt)) {
    swal.fire({
      title: `<span style="color:#f27474">${t("Opps")}!</span>`,
      html: `${t("invalidemail")}.<br /><br /><a href='javascript:swal.close()' class='btn btn-primary btn-sm'>${t("OK")}</a>`,
      type: 'error',
      icon: "error",
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false
  });
  return;
   }


   const axios = require('axios');

  //  let datas = JSON.stringify({
  //    "resetpasswords": {           
  //      "username": usernametxt      
  //    }
  //  });
   let datas = JSON.stringify({             
      "username": usernametxt,
      "sitelanguage":Cookies.get("sitelanguage")
  });
  // console.log(datas);
   let config = {
     method: 'post',
     maxBodyLength: Infinity,
     headers: { 
       'Content-Type': 'application/json'
     },
     body : datas
   };
   //"url": "/userRegistration/ReactResetPassword",
   fetch(`${API_BASE_URL}/userRegistration/ReactResetPassword`, config)
   .then(response => {
       if (!response.ok) {
        // throw new Error('Network response was not ok');
       }
       return response.json(); // Extract the response body as JSON
     })
     .then(data => {           
       if (data.d === 'success') {
         
         swal.fire({
           title: `<span style="color:#5ab328">${t("Success")}</span>`,
           html: `${t("Wehavesharedapasswordresetlinkwith")}: <strong>${usernametxt}</strong>  <br /> <br />   <p><a href='https://www.gmail.com' class='logincircle' target='_blank'><img src=${MEDIA_BASE_URL}/images/gmail.png></a>  <a href='https://login.live.com/' class='logincircle' target='_blank'><img src=${MEDIA_BASE_URL}/images/outlook.png></a>  <a href='https://login.yahoo.com/' class='logincircle' target='_blank'><img src=${MEDIA_BASE_URL}/images/yahoo.png></a></p><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>${t("OK")}</a>`,
           type: 'success',
           icon: "success",
           showCloseButton: true,
           showCancelButton: false,
           showConfirmButton: false
       });       
       
       }
       else if(data.d === 'fail'){
         swal.fire({
           title: `<span style="color:#f27474">${t("Opps")}!</span>`,
           html: `${t("Youhaveenteredwronge-mailorusername")}. <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>${t("OK")}</a>`,
           type: 'error',
          icon: "error",
           showCloseButton: true,
           showCancelButton: false,
           showConfirmButton: false
       });     
       }
 
     })      
   .catch((error) => {   
    swal.fire({
      title: `<span style="color:#f27474">${t("Opps")}!</span>`,
      html: `${t("updationprogress")}.<br /><br /><a href='javascript:swal.close()' class='btn btn-primary btn-sm'>${t("OK")}</a>`,
      type: 'error',
      icon: "error",
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false
  });
  
   //  alert(error +" - Please check internet connection or API!"); 
     setLoading(false);
     return;
   });



}

//////////////////////////////////////////////// handle handlesignwithmicrosoft ///////////////
const handlesignwithmicrosoft =() =>{
  window.location.href=`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=260368b9-0101-4720-b0d8-ff3337affe72&response_type=code&redirect_uri=https://app.zwato.com/LoginMicrosoft&response_mode=query&scope=User.Read`;
}


const [apiKey, setApiKey] = useState('87d7263abc094f35a77b313760406905');
      async function fetchlogindetails(regid, tockenid, tockenmsg){     
        console.log(tockenmsg);

        try {          
            const response = await fetch(`${API_BASE_URL}/userRegistration/getcompanydetailslogin?registrationid=${regid}`, {
              method: 'GET',
              async: false,
              headers: {
                'Content-Type': 'application/json; charset=utf-8'       
              },
              // body: JSON.stringify({
              //   registrationid: regid       
              // })
            });
            const data = await response.json();           
           //console.log(data.d.address1);  

         let expires = new Date()
          expires.setTime(expires.getTime() + (86000 * 1000));         
          setCookie('access_token', `Bearer ${tockenid}`, { path: '/',  expires});     
         setCookie('registrationid', data.d.registrationid, { path: '/',  expires});
         
         if(data.d.language =="" || data.d.language == null)
          data.d.language="English";

         localStorage.setItem('email', data.d.email);
         localStorage.setItem('currency', data.d.Currency);
         localStorage.setItem('sitelanguage', data.d.language);
         localStorage.setItem('dateformate', data.d.dateformate);      
         localStorage.setItem('regstatus', data.d.status);   
         localStorage.setItem('shownameuser', data.d.fname); 
         localStorage.setItem('currentcurrency', data.d.Currency); 
         fetchcurrentcountry();

        savelogininformation(data.d.registrationid);

        loginlogoff(data.d.registrationid);
        if(data.d.registertype=="Company"){
        window.location.href = '/dashboard';
        }
        else if(data.d.registertype=="Accountant"){
          window.location.href = '/dashboard';
        }
        else if(data.d.registertype=="Personal"){
          window.location.href = '/dashboard';
        }
        else{
          window.location.href = '/dashboard';
        }
        return false;        
          } catch (error) {
            // Handle errors here
            console.log("Test Error");
          }
      };


      async function fetchcurrentcountry(){
        fetch('https://api.ipify.org/?format=json')
        .then(response => response.json())
        .then(data =>getcountrydata(data.ip))
        .catch(error => console.error('Error fetching IP address:', error));
      };
      
      async function getcountrydata(ip){
        fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}&ip=${ip}`)
        .then(response => response.json())
        .then(data => showcountrydata(data))
        .catch(error => console.error('Error fetching IP details:', error));
      }

        async function showcountrydata(data){
          //console.log(data);
          localStorage.setItem('currentcountry', data.country_name);
          localStorage.setItem('currentcallingcode', data.calling_code);
          let cntrycode2=data.country_code2.toLowerCase();         
          localStorage.setItem('currentflag', cntrycode2);
          //localStorage.setItem('currentcurrency', data.currency.code); 
        }


///////////////////////////////////////////////////// Login with Google /////////////////////////
// const googleLogin = useGoogleLogin({
//   onSuccess: (tokenResponse) => {
//     console.log('Google login successful', tokenResponse);
//     // You can now use the tokenResponse to authenticate the user in your app
//   },
//   onError: () => {
//     console.error('Google login failed');
//     // Handle login errors here
//   },
//   flow: 'auth-code', // Use 'auth-code' for the authorization code flow
// });

const [ user, setUser ] = useState([]);
    const [ profile, setProfile ] = useState([]);

// const responseMessage = (response) => {
//   console.log(response);
// };
// const errorMessage = (error) => {
//   console.log(error);
// };
       const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });  




    useEffect(
     
      () => {
       
        fetchcurrentcountry();
          if (user) {
              axios
                  .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                      headers: {
                          Authorization: `Bearer ${user.access_token}`,
                          Accept: 'application/json'
                      }
                  })
                  .then((res) => {
                      setProfile(res.data);
// console.log(res.data.email);
// console.log(res.data.name);
// console.log(localStorage.getItem("currentcountry"));
// console.log(localStorage.getItem("currentcallingcode"));
// console.log(localStorage.getItem("currentcurrency"));
// console.log(res.data);

                      const axios = require('axios');
                      // let datas = JSON.stringify({
                      //   "googlelogindata": {           
                      //     "Email": res.data.email,
                      //     "FName": res.data.given_name,
                      //     "LName": res.data.family_name,
                      //     "country": localStorage.getItem("currentcountry"),
                      //     "currency": localStorage.getItem("currentcurrency"),
                      //     "countrycode": localStorage.getItem("currentcallingcode"),
                      //     "registertype": formFields.registertype,
                      //     "keepmesign": formFields.keepmesign 
                      //   }
                      // });
                      let datas = JSON.stringify({                                 
                          "Email": res.data.email,
                          "FName": res.data.given_name,
                          "LName": res.data.family_name,
                          "country": localStorage.getItem("currentcountry"),
                          "currency": localStorage.getItem("currentcurrency"),
                          "countrycode": localStorage.getItem("currentcallingcode"),
                          "registertype": formFields.registertype,
                          "keepmesign": formFields.keepmesign 
                      
                      });
                     // console.log(datas);
                      let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        headers: { 
                          'Content-Type': 'application/json'
                        },
                        body : datas
                      };
                     // "url": "/userRegistration/ReactGoogleLogin",
                      fetch(`${API_BASE_URL}/userRegistration/ReactGoogleLogin`, config)
                      .then(response => {
                          if (!response.ok) {
                           // throw new Error('Network response was not ok');
                          }
                          return response.json(); // Extract the response body as JSON
                        })
                        .then(data => { 
                          let expires = new Date()
                          expires.setTime(expires.getTime() + (86000 * 1000));         
                          setCookie('access_token', `Bearer ${data.access_token}`, { path: '/',  expires});  
                          fetchlogindetails(data.d, data.access_token, data.msg);

                          
                        })      
                      .catch((error) => {
                       // console.log("Test Error-"+error);
                       swal.fire({
                        title: `<span style="color:#f27474">${t("Opps")}!</span>`,
                        html: `${t("updationprogress")}.<br /><br /><a href='javascript:swal.close()' class='btn btn-primary btn-sm'>${t("OK")}</a>`,
                        type: 'error',
                        icon: "error",
                        showCloseButton: true,
                        showCancelButton: false,
                        showConfirmButton: false
                    });
                    
                       // alert(error +" - Please check internet connection or API");
                       // console.log(error);
                        
                        setLoading(false);
                        return;
                      });




                  })
                  .catch((err) => console.log(err));
          }
      },
      [user]
  );

 const savelogininformation =(regid) =>{
 

  let devices="";
  let osnames=deviceInfo.osName;
  if(isMobile){
    devices="Mobile"
    osnames=deviceName.split(' ')[0];
  }
  else if(isTablet){
    devices="Tablet"
  }
  else if(isDesktop){
    devices="Desktop or Laptop"
  }


  
  const axios = require('axios');
  
  // let datas = JSON.stringify({
  //   "logininformationdata": {
  //     "registrationid": regid,  
  //     "device": devices,
  //     "browser":browserName,
  //     "devicename":deviceName,
  //     "osname":osnames,
  //     "osversion":deviceInfo.osVersion, 
  //     "useragent":navigator.userAgent
      
  //   }
  // });
  let datas = JSON.stringify({    
      "registrationid": regid,  
      "device": devices,
      "browser":browserName,
      "devicename":deviceName,
      "osname":osnames,
      "osversion":deviceInfo.osVersion, 
      "useragent":navigator.userAgent
  });

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    headers: { 
      'Content-Type': 'application/json'
    },
    body : datas
  };
  fetch(`${API_BASE_URL}/userRegistration/SaveLoginInformation`, config)
  .then((response) => {
     if(response.ok){
      
  
      // swal.fire({
      //   title: '<span style="color:#5ab328">Success</span>',
      //   html: "updated'. <br /><br /><a href='javascript:swal.close()' class='btn btn-primary btn-sm'>OK</a> ",
      //   type: 'success',
      //   icon: "success",
      //   showCloseButton: false,
      //   showCancelButton: false,
      //   showConfirmButton: false
      // });
    
  
     }
  })
  .catch((error) => {
    console.log(error);
  });



 }

const loginlogoff =(regid)=>{


  
  const axios = require('axios');
  
  // let datas = JSON.stringify({
  //   "loginlogoffdata": {
  //     "registrationid": regid,  
  //     "currentlogin": "Login"
  //   }
  // });
  let datas = JSON.stringify({    
      "registrationid": regid,  
      "currentlogin": "Login"    
  });
  //console.log(datas);
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    headers: { 
      'Content-Type': 'application/json'
    },
    body : datas
  };
  //"url": "/userRegistration/LoginLogoff",
  fetch(`${API_BASE_URL}/userRegistration/LoginLogoff`, config)
  .then((response) => {
     if(response.ok){  
     }
  })
  .catch((error) => {
    console.log(error);
  });
}

  
    
    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
        <>
         {loading ? (
        <Loader1 />
      ) : (
        ""
      )}

{/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}

        {/* <div>
            <p>Device Type: {deviceType}</p>
             <p>Device Brand: {deviceInfo.osName}</p> 
             <p>Device Model: {deviceInfo.osVersion}</p> 
          
             <p>Device Name: {deviceName}</p>
        </div>

         <div>
            <p>User Agent: {navigator.userAgent},-- Platform: {navigator.platform},-- Language: {navigator.language}</p>     
        </div>

        <div>
            <p>Is Mobile: {isMobile ? 'Yes' : 'No'},-- Is Browser: {isBrowser ? 'Yes' : 'No'},-- Is tablet: {isTablet ? 'Yes' : 'No'},-- Is desktop : {isDesktop ? 'Yes' : 'No'},-- Is IOS : {isIOS ? 'Yes' : 'No'},-- {browserName},-- {deviceType}</p>
        </div> */}

{/* ////////////////////////////////////////// Modal History ///////////////////////////////////////// */}
<div className="modal fade modal_reset_password" id="ModalResetPassword" tabIndex="-1" role="dialog" aria-hidden="true">
              <div className={`modal-dialog ${isOpen ? 'rollIn' : 'rollOut'} animated  modal-md modal-dialog-centered`}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">{t("ForgotPassword")}</h4>
                    <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body hcj">
                 <div className="container-fluid">
                 <div className="form-group row">                         
                         <div className="col-md-12 col-12 text-center">
                            <strong> {t("Ifyou'velostyourpasswordordonotremember")}</strong><br />
                           <small> {t("fillregisteredemail")}.<br />
                            ${t("sendyoualinktoresetpassword")}.</small>
                         </div>
                     </div>
                     <div className="form-group row">                   
                         <div className="col-md-12 col-12">
                         <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1"><i className="fa-regular fa-user"></i></InputGroup.Text>
        <Form.Control placeholder={`${t("RegisteredEmailorUsername")}`} id="txtforgotpassword" className="validemail" aria-describedby="basic-addon1" />
      </InputGroup>
                         </div>
                     </div>
                     <div className="form-group row">                   
                         <div className="col-md-12 col-12 d-grid gap-2">
                         <Button variant="primary" onClick={handleresetpassword} type="submit" id="btnforgot" className="btn-block"> {t("ResetPassword")} </Button>
                          </div>
                          </div>
                 </div>
             </div>
         </div>
     </div>
 </div>



            <div className="login-8">
                <div className="container">
                    <div className="row justify-content-center login-box">
                        <div className="col-lg-6 col-md-12 form-info">
                           <div className="row">
                                                          <div className="col-lg-9 col-md-9"></div>
                                                          <div className="col-lg-3 col-md-3"><LanguageSelector /></div>
                                                      </div>
                            <div className="form-section">
                                <div className="logo1 clearfix text-center">
                                    <Link to="/">
                                        <img src={publicUrl+"assets/images/logo-zwato.png"} alt="logo"/>
                                    </Link>
                                </div>                                
                                <h3 className="text-center">{t("SignIntoYourAccount")}</h3>
                                  <Form onSubmit={handlesubmit}>
                                <div className="login-inner-form">
                                  <div className="form-box m-b-20">
                                <div className="m-checkbox-inline justify-content-between custom-radio-ml" style={{display:"none"}}>
                                    <div className="form-check form-check-inline radio radio-primary">
                                        <input className="form-check-input" checked={formFields.registertype === 'Company'}
           onChange={handleChange} id="radioinline1" type="radio" name="registertype" value="Company"/>
                                        <label className="form-check-label mb-0" htmlFor="radioinline1">{t("Company")}</label>
                                    </div>
                                    <div className="form-check form-check-inline radio radio-primary">
                                        <input className="form-check-input" checked={formFields.registertype === 'Accountant'} onChange={handleChange} id="radioinline2" type="radio" name="registertype" value="Accountant"/>
                                        <label className="form-check-label mb-0" htmlFor="radioinline2">{t("Accountant")}</label>
                                    </div>
                                    <div className="form-check form-check-inline radio radio-primary">
                                        <input className="form-check-input" checked={formFields.registertype === 'Personal'} onChange={handleChange} id="radioinline3" type="radio" name="registertype" value="Personal"/>
                                        <label className="form-check-label mb-0" htmlFor="radioinline3">{t("Personal")}</label>
                                    </div>
                                </div>
                                </div>
                                    <Form.Group className="form-group form-box">
                                        <Form.Control type="email" name="username" onChange={handleChange} id="username" value={username} placeholder={`${t("UserNameOrEmail")}`}/>
                                        <i className="fa-regular fa-user"></i>
                                    </Form.Group>
                                    <Form.Group className="form-group form-box">
                                        <Form.Control type={showPassword ? "text" : "password"} name="password" onChange={handleChange} value={password} placeholder="************"/>
                                        <i onClick={togglePasswordVisibility}>
            {showPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
          </i>
                                    </Form.Group>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <div className="form-check checkbox checkbox-dark p-l-0">
                                                <input type="checkbox" name="keepmesign" checked={isChecked} 
          onChange={handleChangetype} className="radio_animated check checkbox-custom" id="checkbox-custom_04"/>
                                                <label htmlFor="checkbox-custom_04" className="checkbox-custom-label mt-0">{t("Keepmesignedin")}</label>
                                            </div>
                                        </div>
                                        <div className="col text-end">                                            
                                            <Link to="#" data-bs-toggle="modal" data-bs-target=".modal_reset_password">{t("ResetPassword")} ?</Link>
                                        </div>
                                    </div>
                                    
                                    <div className="form-group mb-0">
                                        <Button className="btn btn-info-gradien w-100" type="submit"><i className="fa-solid fa-arrow-right-to-bracket"></i> {t("SignIn")}</Button>                    
                                    </div>
                                    <div className="do-mobile mt-3">
                                        <div className="fxt-style-line">
                                            <h2 className="text-dark">{t("OrSigninWith")}</h2>
                                        </div>
                                        <ul className="fxt-socials">
                                            <li className="fxt-google"><Link to="#" title="google"><i className="fa-brands fa-google"></i><span>{t("SigninWithGoogle")}</span></Link></li>
                                            <li className="fxt-twitter"><Link to="#" title="twitter"><i className="fa-brands fa-microsoft"></i><span>{t("SigninWithMicrosoft")}</span></Link></li>
                                        </ul>
                                    </div>
                                    <p className="text">{t("Don'tHaveanAccount?")} <Link to="/RegisterNow" className="txt-primary"> {t("SignUp")}</Link></p>
                                </div>
                                </Form>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 bg-img">
                            <div className="info">
                                <div className="info-text">
                                    <div className="logo clearfix text-center">
                                        <Link to="/">
                                            <img src={publicUrl+"assets/images/logo-zwato-w.png"} alt="logo"/>
                                        </Link>
                                    </div>
                                    <div className="fxt-style-line">
                                        <h2>{t("OrSigninWith")}</h2>
                                    </div>
                                    <ul className="fxt-socials">
                                        <li className="fxt-google"><Link to="#" title="Sign in With Google" onClick={login}><i className="fa-brands fa-google"></i><span>{t("SigninWithGoogle")}</span></Link></li>
                                        <li className="fxt-twitter"><Link to="#" title="Sign in With Microsoft" onClick={handlesignwithmicrosoft}><i className="fa-brands fa-microsoft"></i><span>{t("SigninWithMicrosoft")}</span></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export  default SignIn;