import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useCookies } from 'react-cookie';
import Loader1 from '../global-component/Loader1';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import swal from "sweetalert2";
import InputGroup from 'react-bootstrap/InputGroup';
import jQuery from "jquery";
import $ from "jquery";
import { isMobile, isBrowser, deviceType, isTablet, isDesktop, browserName, isIOS,deviceDetect  } from 'react-device-detect';
import { NavItem } from "react-bootstrap";
import { API_BASE_URL, MEDIA_BASE_URL, BASE_URL } from "../../config/config";
import Cookies from 'js-cookie';

function LoginMicrosoft() {

  const deviceInfo = deviceDetect();
  const { device } = deviceDetect();
  const userAgent = navigator.userAgent;
// Example regular expression to find device name
const match = userAgent.match(/\((.*?)\)/);
let deviceName = '';

if (match && match.length > 1) {
    const parts = match[1].split(';');
    if (parts.length > 0) {
      if(isMobile)
        deviceName = parts[1].trim();
      else
      deviceName = parts[0].trim();
    }
}



  const [apiKey, setApiKey] = useState('87d7263abc094f35a77b313760406905');
  const [cookies, setCookie] = useCookies(['access_token', 'refresh_token']);
  const [loading, setLoading] = useState(false);
     async function  fetchcurrentcountry(){
        fetch('https://api.ipify.org/?format=json')
        .then(response => response.json())
        .then(data =>getcountrydata(data.ip))
        .catch(error => console.error('Error fetching IP address:', error));
      };
      
    async function getcountrydata(ip){
        fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}&ip=${ip}`)
        .then(response => response.json())
        .then(data => showcountrydata(data))
        .catch(error => console.error('Error fetching IP details:', error));
    }
    
    async function showcountrydata(data){       
        $("#country").val(data.country_name);
        $("#currency").val(data.currency.code);
        $("#countrycode").val(data.calling_code);
    }

    async function fetchlogindetails(regid, tockenid, tockenmsg){      

        try {
            const response = await fetch(`${API_BASE_URL}/userRegistration/getcompanydetailslogin?registrationid=${regid}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json; charset=utf-8'       
              },
              // body: JSON.stringify({
              //   registrationid: regid       
              // })
            });
            const data = await response.json();           
           //console.log(data.d.address1);  

           let expires = new Date()
           expires.setTime(expires.getTime() + (86000 * 1000));         
           setCookie('access_token', `Bearer ${tockenid}`, { path: '/',  expires});     
          setCookie('registrationid', data.d.registrationid, { path: '/',  expires});
          
          if(data.d.language =="" || data.d.language == null)
           data.d.language="English";
 
 
          localStorage.setItem('email', data.d.email);
          localStorage.setItem('currency', data.d.Currency);
          localStorage.setItem('sitelanguage', data.d.language);
          localStorage.setItem('dateformate', data.d.dateformate);      
          localStorage.setItem('regstatus', data.d.status);          

         fetchcurrentcountrys();

         savelogininformation(data.d.registrationid);
         loginlogoff(data.d.registrationid);

        window.location.href = '/dashboard';
        return false;

        
          } catch (error) {
            // Handle errors here
            console.log("Test Error");
          }

      };

      const savelogininformation =(regid) =>{
 

        let devices="";
        let osnames=deviceInfo.osName;
        if(isMobile){
          devices="Mobile"
          osnames=deviceName.split(' ')[0];
        }
        else if(isTablet){
          devices="Tablet"
        }
        else if(isDesktop){
          devices="Desktop or Laptop"
        }
      
      
        
        const axios = require('axios');
        
        // let datas = JSON.stringify({
        //   "logininformationdata": {
        //     "registrationid": regid,  
        //     "device": devices,
        //     "browser":browserName,
        //     "devicename":deviceName,
        //     "osname":osnames,
        //     "osversion":deviceInfo.osVersion, 
        //     "useragent":navigator.userAgent
            
        //   }
        // });

        let datas = JSON.stringify({    
          "registrationid": regid,  
          "device": devices,
          "browser":browserName,
          "devicename":deviceName,
          "osname":osnames,
          "osversion":deviceInfo.osVersion, 
          "useragent":navigator.userAgent
      });

       
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          headers: { 
            'Content-Type': 'application/json'
          },
          body : datas
        };
        
        fetch(`${API_BASE_URL}/userRegistration/SaveLoginInformation`, config)
        .then((response) => {
           if(response.ok){
            
        
            // swal.fire({
            //   title: '<span style="color:#5ab328">Success</span>',
            //   html: "updated'. <br /><br /><a href='javascript:swal.close()' class='btn btn-primary btn-sm'>OK</a> ",
            //   type: 'success',
            //   icon: "success",
            //   showCloseButton: false,
            //   showCancelButton: false,
            //   showConfirmButton: false
            // });
          
        
           }
        })
        .catch((error) => {
          console.log(error);
        });
      
      
      
       }
      
      const loginlogoff =(regid)=>{
      
      
        
        const axios = require('axios');
        
        // let datas = JSON.stringify({
        //   "loginlogoffdata": {
        //     "registrationid": regid,  
        //     "currentlogin": "Login"
        //   }
        // });
        let datas = JSON.stringify({    
          "registrationid": regid,  
          "currentlogin": "Login"    
      });
        //console.log(datas);
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          headers: { 
            'Content-Type': 'application/json'
          },
          body : datas
        };
        
        fetch(`${API_BASE_URL}/userRegistration/LoginLogoff`, config)
        .then((response) => {
           if(response.ok){  
           }
        })
        .catch((error) => {
          console.log(error);
        });
      }
      
      
      async function fetchlogindatafromserver(code){      
        
        const axios = require('axios');

        // let datas = JSON.stringify({
        //   "codedata": {           
        //     "codes": code,
        //     "country":  $("#country").val(),
        //     "currency": $("#currency").val(),
        //     "countrycode": $("#countrycode").val() 
        //   }
        // });
        let datas = JSON.stringify({    
          "codes": code,  
          "country": $("#country").val(),
          "currency": $("#currency").val(),
          "countrycode":$("#countrycode").val()
      });
       // console.log(datas);
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          headers: { 
            'Content-Type': 'application/json'
          },
          body : datas
        };
        //"url": "/userRegistration/ReactLoginwithMicrosoft",
        fetch(`${API_BASE_URL}/userRegistration/ReactLoginwithMicrosoft`, config)
        .then(response => {
            if (!response.ok) {
             // throw new Error('Network response was not ok');
            }
            return response.json(); // Extract the response body as JSON
          })
          .then(data => {  
            
            if(data.d != null){      
            if (data.d === 'AccessDenied') {          
              swal.fire({
                title: '<span style="color:#f27474">Opps!</span>',
                html: "Access denied <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
                type: 'error',
                icon: "error",
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false
            });
            }       
            else {
              let expires = new Date()
              expires.setTime(expires.getTime() + (86000 * 1000));         
              setCookie('access_token', `Bearer ${data.access_token}`, { path: '/',  expires});  
                fetchlogindetails(data.d, data.access_token, data.msg); 
            }
          }
          else{
            swal.fire({
              title: '<span style="color:#f27474">Opps!</span>',
              html: "Email not found! <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
              type: 'error',
              icon: "error",
              showCloseButton: true,
              showCancelButton: false,
              showConfirmButton: false
          });
          }
          })      
        .catch((error) => {
         // console.log("Test Error-"+error);
         swal.fire({
          title: 'Opps!',
          html: "Updation in a progress! Please try after sometime.<br /><br /><a href='javascript:swal.close()' class='btn btn-primary btn-sm'>OK</a> ",
          type: 'error',
          icon: "error",
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: false
      });  
         // alert(error +" - Please check internet connection or API");          
          setLoading(false);
        });

      };


      async function fetchcurrentcountrys(){
        fetch('https://api.ipify.org/?format=json')
        .then(response => response.json())
        .then(data =>getcountrydatas(data.ip))
        .catch(error => console.error('Error fetching IP address:', error));
      };
      
      async function getcountrydatas(ip){
        fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}&ip=${ip}`)
        .then(response => response.json())
        .then(data => showcountrydatas(data))
        .catch(error => console.error('Error fetching IP details:', error));
      }

        async function showcountrydatas(data){
          //console.log(data);
          localStorage.setItem('currentcountry', data.country_name);
          localStorage.setItem('currentcallingcode', data.calling_code);
          let cntrycode2=data.country_code2.toLowerCase();         
          localStorage.setItem('currentflag', cntrycode2);
          //localStorage.setItem('currentcurrency', data.currency.code); 

        }



    useEffect(() => {
      fetchcurrentcountry();
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get('code'); 
 //alert(code);
 setTimeout(() => {
fetchlogindatafromserver(code);
}, 1000);
   

}, []);

    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
<>
<input type="hidden" id="country"/>
<input type="hidden" id="currency"/>
<input type="hidden" id="countrycode"/>
</>
    );

}
export  default LoginMicrosoft;
    