import React, { useEffect, useState, KeyboardEvent,useRef  } from "react";
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import $ from "jquery";
import axios from 'axios';
import Levenshtein from 'fast-levenshtein';
import { API_BASE_URL,MEDIA_BASE_URL,BASE_URL } from '../../config/config';
import swal from "sweetalert2";
import 'select2/dist/css/select2.css';
import 'select2/dist/js/select2.full.js';
import { io } from 'socket.io-client';
const SOCKET_URL = 'https://chat.zwato.com';
const socket = io(SOCKET_URL);

function ChatSec () {

  const [chatmessage, setChatMessage] = useState([]);
  const [messages, setMessages] = useState([]);
  const [catmessages, setCatMessages] = useState([]);
  const [flag, setFlag] = useState(0);
  const [ansflag, setAnsFlag] = useState(0);
  const [talkflag, setTalkFlag] = useState(0);
  const [input, setInput] = useState('');
  const [time, setTime] = useState('');
  const [talkwithSupport, settalkwithSupport] = useState(0);
  const endOfMessagesRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
    const messagesEndRef = useRef(null);
    const chatContainerRef = useRef(null);
    const [registrationId, setRegistrationId] = useState(`${Cookies.get("registrationid")}`);
    const [isSendingMessage, setIsSendingMessage] = useState(false);

  async function fetchchatmessage() {
    try {
      //"url": "/chat/getChat?registrationid=10001&status=Open",
      const response = await fetch(`${API_BASE_URL}/chat/getChat?registrationid=${Cookies.get("registrationid")}&status=Open`, {
        method: 'GET',
        headers: {
          "Authorization": Cookies.get("access_token"),
          'Content-Type': 'application/json; charset=utf-8'       
        },
        // body: JSON.stringify({
        //   registrationid: Cookies.get("registrationid"),
        //   status: "Open"
        // })
      });      
      const data = await response.json();
      if(data.d.length >0){
    $("#hdnchatid").val(data.d[0].chatid);
      }
      setChatMessage(data.d);
       if(data.d.length<=0)
        await msgFromAdminDefault("Hello! Welcome to Zwato. We are excited to have you here. Please let us know how we can help you get started.");
    } catch (error) {
      //console.log("Test Error");
    }
  }

  const sendMessageNew = async (value) => {
    if (value.trim() === '')
       return;

    const userMessage = { sender: 'user', text: input, projects: '' };
    setMessages([...messages, userMessage]);

    try {
       //"url": "/chat/GetchatQuestionAnswerbyCategory?category=Account Problem",
        const response = await fetch(`${API_BASE_URL}/chat/GetchatQuestionAnswerbyCategory?category=${value}`, {
          method: 'GET',
          headers: {
            "Authorization": Cookies.get("access_token"),
            'Content-Type': 'application/json; charset=utf-8'       
          },
          // body: JSON.stringify({
          //   registrationid: Cookies.get("registrationid"),
          //   category: value
          // })
        });
        
        const data = await response.json();
        console.log(data.d.length);
        if(data.d && data.d.length>0){
          setFlag(1);
          setAnsFlag(0);
          setCatMessages(data.d);
        } else {
            const botMessage = { sender: 'bot', text: "Sorry, I could not find an answer to your question.", projects: '' };
            setMessages(prevMessages => [...prevMessages, botMessage]);
            await msgFromAdmin(botMessage.text);
            await resolveyourIssue('no',1);
        }
    } catch (error) {
        //console.error('Error fetching answer:', error);
    }
    setInput('');
};
  const sendMessage = async (value='') => {
    setFlag(0);
    //console.log(value);
    if (input.trim() === '' && value==='')
       return;

    const userMessage = { sender: 'user', text: input, projects: '' };
    setMessages([...(messages || []), userMessage]);

    try {
        const response = await fetch(`${API_BASE_URL}/chat/GetchatQuestionAnswer?question=${input}`, {
          method: 'GET',
          headers: {
            "Authorization": Cookies.get("access_token"),
            'Content-Type': 'application/json; charset=utf-8'       
          },
          // body: JSON.stringify({
          //   registrationid: Cookies.get("registrationid"),
          //   question: input
          // })
        });
        
        const data = await response.json();
        const questionsAnswers = data.d;
        let bestMatch = null;
        let bestScore = Number.MAX_VALUE;

        questionsAnswers.forEach(qa => {
            const score = Levenshtein.get(input, qa.question);
            if (score < bestScore) {
                bestScore = score;
                bestMatch = qa;
            }
        });
     // //console.log(bestScore);
        if (bestScore <= input.length * 1) { 
            const botMessage = { sender: 'bot', text: bestMatch.answer, projects: bestMatch.projects };
            await msgFromAdmin(bestMatch.answer);
            setMessages(prevMessages => [...prevMessages, botMessage]);
        } else {
            const botMessage = { sender: 'bot', text: "Sorry, I could not find an answer to your question.", projects: '' };
            setMessages(prevMessages => [...prevMessages, botMessage]);
            await msgFromAdmin(botMessage.text);
            await resolveyourIssue('no',1);
        }
    } catch (error) {
        //console.error('Error fetching answer:', error);
    }
    setInput('');
};

useEffect(() => {
  // socket.on('connect_error', (error) => {
  //   console.error('Connection error:', error);
  //   socket.connect(); // Attempt to reconnect
  // });
	// Join the room on component mount
  console.log(registrationId);
	socket.emit('joinRoom', `room_${registrationId}`);

	// Listen for incoming messages
	socket.on('receiveMessage', (newMessage) => {
		console.log('Admin received message:', newMessage);
		setChatMessage((prevMessages) => [...prevMessages, {
      'registrationid' : registrationId, 
      'message' : newMessage.message,
      'messagefrom':newMessage.messagefrom,
       messagetime: new Date().toISOString(),
       messagefromname:newMessage.messagefrom=="admin"?"ChatBot":localStorage.getItem("shownameuser"),
      'messageto':newMessage.messageto,
      'image':'','status':'Open'}]);
	});

	// Fetch existing messages  
	const fetchMessages = async () => {
	  try {
		const response = await fetch(
		  `${API_BASE_URL}/customerpanel/Getchat?registrationid=${registrationId}&status=Open`,
		  {
			headers: {
			  Authorization: Cookies.get('access_token'),
			},
		  }
		);
		const data = await response.json();
		setMessages(data.d);
	  } catch (error) {
		console.error('Failed to fetch messages:', error);
	  }
	};

	fetchMessages();

	// Cleanup on component unmount
	return () => {
	  socket.off('receiveMessage');
	};
  }, [registrationId]);
  const validateAndParseTimestamp = (customTimestamp) => {
    const inputTimestamp = customTimestamp?.trim() || new Date().toISOString();
      const formattedTimestamp =
      inputTimestamp.includes("T")
        ? inputTimestamp
        : inputTimestamp.replace(/(\d{1,2})([APM]{2})/, "$1 $2") + " UTC";  
  
      const parsedDate = new Date(formattedTimestamp);
      if (isNaN(parsedDate)) {
        return { error: "Invalid time: Timestamp is not valid", date: null };
      }
    
      const now = new Date();
      const maxFuture = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);
      const minPast = new Date(now.getTime() - 10 * 365 * 24 * 60 * 60 * 1000); 
  
      if (parsedDate > maxFuture) {
        return { error: "Invalid time: Timestamp is too far in the future", date: null };
      }
      if (parsedDate < minPast) {
        return { error: "Invalid time: Timestamp is too far in the past", date: null };
      }
    
      return { error: null, date: parsedDate };
    };
  
  const localizeTime = (customTimestamp) => {
    const { error, date } = validateAndParseTimestamp(customTimestamp);
    if (error) return error;
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localizedDate = new Date(date.toLocaleString("en-US", { timeZone: userTimezone }));
    return new Intl.DateTimeFormat("en-US", {
      timeZone: userTimezone,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(localizedDate);
  };
  
const sendMessageExisting = async (question) => {
  setFlag(0);
  setAnsFlag(0);
  const userMessage = { sender: 'user', text: question, projects: '' };
  setMessages([...messages, userMessage]);
  await msgFromUserDefaultNew(question);
  try {
    const result = catmessages.find((msg) => msg.question === question);
    const answer= result ? result.answer : 'Answer not found';
          const botMessage = { sender: 'bot', text: answer, projects: '' };
          setMessages(prevMessages => [...prevMessages, botMessage]);
          await msgFromAdminDefault(answer);
          await msgFromAdminDefault('Did this answer resolve your issue?');
          setAnsFlag(1);
  } catch (error) {
      //console.error('Error fetching answer:', error);
  }
};

const resolveyourIssue = async (ans,flm) => {
  setFlag(0);
  setAnsFlag(0);
  setTalkFlag(0);
  if(flm===0)
await msgFromUserDefaultNew(ans);
if(ans==='yes'){
  await msgFromAdminDefault('Great to hear that the issue is resolved! If you have any more questions or need further assistance, please connect with us or contact admin support');
}else{
  setTalkFlag(1);
  await msgFromAdminDefault('Would you like to speak with our support team for further assistance?');
}
};

const talkwithsupportTeam = async (ans) => {
  setFlag(0);
  setAnsFlag(0);
  setTalkFlag(0);
await msgFromUserDefaultNew(ans);
if(ans==='yes'){
  $("#chattxtbox").show();
  settalkwithSupport(1);
}
else if(ans==='ticket'){
window.location.href="/OpenTicket";
}
else{
  await msgFromAdminDefault('Thank you for contacting us. If you have any further questions or need additional support, please do not hesitate to reach out');
}
};

const handleProblemClick = async(label) =>{
 await getFormattedDateTime();
 setTimeout(async () => {
  await msgFromUserDefault(label);
}, 500);
}
const msgFromUserDefault = async(value) =>{
  let message = value;
  if (message == "")
      return;
  const messageData = {
    registrationId,
    message: message,
    messagefrom: 'user', // Adjust as needed
    messageto:'admin',
    access_token:Cookies.get('access_token'),
    messagefromname:localStorage.getItem("shownameuser"),
    status:'Open',
    sitelanguage:Cookies.get('sitelanguage') 
  };

  // Emit message to server
  setIsSendingMessage(true);
  socket.emit('sendMessage', messageData);
  
  setInput(value);
  await sendMessageNew(value);
  setIsSendingMessage(false);
}

const msgFromUserDefaultNew = async(value) =>{
  let message = value;
  if (message == "")
      return;
  const messageData = {
    registrationId,
    message: value,
    messagefrom: 'user', // Adjust as needed
    messageto:'admin',
    access_token:Cookies.get('access_token'),
    messagefromname:localStorage.getItem("shownameuser"),
    status:'Open',
    sitelanguage:Cookies.get('sitelanguage') 
  };

  // Emit message to server
  setIsSendingMessage(true);
  socket.emit('sendMessage', messageData);
 
  setIsSendingMessage(false);
}
  const msgFromAdmin = async(value) =>{
    let message = $("#chatmessage").val();
    if (message == "")
        return;

    const messageData = {
      registrationId,
      message: value,
      messagefrom: 'admin', // Adjust as needed
      messageto:'user',
      access_token:Cookies.get('access_token'),
      messagefromname:"ChatBot",
      status:'Open',
      sitelanguage:Cookies.get('sitelanguage') 
    };
  
    // Emit message to server
    setIsSendingMessage(true);
    socket.emit('sendMessage', messageData);
    
    setIsSendingMessage(false);
  }
  const msgFromAdminDefault = async(value='') =>{
    let message = value;
    if (message == "")
        return;

    //let cnvrtdmsg= await translateMessage(value, "English");
   
    const messageData = {
      registrationId,
      message: value,
      //convertedchat:cnvrtdmsg,
      messagefrom: 'admin', // Adjust as needed
      messageto:'user',
      access_token:Cookies.get('access_token'),
      messagefromname:"ChatBot",
      status:'Open',
      sitelanguage:Cookies.get('sitelanguage') 
    };
  
    // Emit message to server
    setIsSendingMessage(true);
    socket.emit('sendMessage', messageData);
    
    setIsSendingMessage(false);
  }
/////////////////////////////////////// handlesendmessage //////////////////////////
const handlesendmessage = async(e) =>{
  e.preventDefault();
  let message = $("#chatmessage").val();

  if (message == "")
      return;

  //let cnvrtdmsg= await translateMessage(message,"English");

  const messageData = {
    registrationId,
    message: message,    
    //convertedchat:cnvrtdmsg,
    messagefrom: 'user', // Adjust as needed
    messageto:'admin',
    access_token:Cookies.get('access_token'),
    messagefromname:localStorage.getItem("shownameuser"),
    status:'Open',
    sitelanguage:Cookies.get('sitelanguage') 
  };
 
  setTalkFlag(0);
  // Emit message to server
  setIsSendingMessage(true);
  socket.emit('sendMessage', messageData);
 // setMessages((prevMessages) => [...prevMessages, messageData]);
  setMessages((prevMessages) => [...(prevMessages || []), messageData]);
  $("#chatmessage").val("");
  setInput('');
  if(talkwithSupport===0)
  await sendMessage();
  $("#chatmessage").val("");
  setInput('');
  setIsSendingMessage(false);
}
function OnSuccesssavechat(response){
  fetchchatmessage();
  $("#chatmessage").val("");
  setInput("");
}

////////////////////////////////////////////////// Updload image for chat ///////////////////////////////////
const [uploadedfile, setUploadedFile] = useState('');
const handleFileChange = async (event) =>{
    const formData = new FormData();
    //formData.append('userlogo', event.target.files[0]);
     formData.append('registrationid', $("#registrationid").val());
     formData.append('message', "");
     formData.append('messagefrom', "user");
     formData.append('messageto', "admin");     
     formData.append('status', "Open");
     formData.append('image', event.target.files[0]);
    // formData.append('extension', event.target.files[0]);
    // //console.log(JSON.stringify({"data":formData}));
     try {
        
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
          "Authorization": Cookies.get("access_token")
        },
        mimeType: "multipart/form-data",
        body : formData
      };
      
      fetch(`${API_BASE_URL}/chat/SaveChatDatawithphoto`, config)
      .then((response) => {
      
        if (!response.ok) {
         // throw new Error('Network response was not ok');
        }
        return response.json(); // Extract the response body as JSON
      
       // console.log(response.ok);
      })
      .then(data => { 
        OnSuccesssavechat(data.d);
      });


      
     
       } catch (error) {
         //console.error('Error uploading file:', error);        
       } finally {
       // setUploadedFile(null); // Reset the selected file
       }
}

//////////////////////////////////  handleclosechat  ////////////////////////
const handleclosechat =(e) =>{
  // if (confirm("Are you sure you want to leave from chat?")) {
  //   $(".chatboxadmin").hide();
  //   $("#chaticon").show();

  if($("#hdnchatid").val()==""){
     swal.fire({
        title: '<span style="color:#f27474">Opps!</span>',
        html: "Chat is not open! <br /><br /><a href='javascript:swal.close()' class='btn btn-primary btn-sm'>OK</a> ",
        type: 'error',
        icon: "error",
        showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false
      });
      return;
  }
  const emaildidval=localStorage.getItem("email");

    $.ajax({
        "url": `${API_BASE_URL}/usermenu/closeChat`,
       // data: "{'registrationid' : '"+$("#registrationid").val()+"', 'messagefrom':'user', 'messageto':'admin', 'status':'Close','emailid':'"+emaildidval+"'}",
        data:JSON.stringify({
          'registrationid' : $("#registrationid").val(),
           'closedby':localStorage.getItem("shownameuser"), 
           'chatid':$("#hdnchatid").val(),
            'emailid':emaildidval}),
        method: 'post',
        dataType: 'json',
        headers: {
          "Authorization": Cookies.get("access_token"),
          'Content-Type': 'application/json; charset=utf-8'       
        },
        success: function (data) {
            OnSuccesschatClose(data.d);
        }
    });
//}
}

const OnSuccesschatClose =(response) =>{
 // $("#chatboxshow").html("");
  fetchchatmessage();
}

    useEffect(() => {  
fetchchatmessage();
},[]);


useEffect(() => {
  // Auto-scroll only if user is at the bottom
  if (isAtBottom) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }
}, [chatmessage, isAtBottom]);


const handleScroll = () => {
  const container = chatContainerRef.current;
  const bottom = container.scrollHeight === container.scrollTop + container.clientHeight;
  setIsAtBottom(bottom);
};


const getFormattedDateTime = () => {
  const now = new Date();
  
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = now.getFullYear();
  
  let hours = now.getHours();
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const hoursStr = String(hours).padStart(2, '0');

  setTime( `${day}/${month}/${year} ${hoursStr}:${minutes} ${ampm}`);
};

const handleKeyPress = async(event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    let message = $("#chatmessage").val();
  
    if (message == "")
        return;
    const messageData = {
      registrationId,
      message: message,
      messagefrom: 'user', // Adjust as needed
      messageto:'admin',
      access_token:Cookies.get('access_token'),
      messagefromname:localStorage.getItem("shownameuser"),
      status:'Open',
      sitelanguage:Cookies.get('sitelanguage') 
    };
  
    // Emit message to server
    setIsSendingMessage(true);
    socket.emit('sendMessage', messageData);
    
    if(talkwithSupport===0)
    await sendMessage();
    setIsSendingMessage(false);
    $("#chatmessage").val("");
    setInput('');
  }
};

const lastMessageRef = useRef(null);
useEffect(() => {
  if (lastMessageRef.current) {
  // Scroll into view for the last message
  lastMessageRef.current.scrollIntoView({ behavior: "auto", block: "nearest" });
  $("#chattxtbox").show();
  }
  }, [chatmessage]);


  useEffect(()=>{
    $("#chattxtbox").hide();
  },[]);


 

  
   


  async function translateMessage(text, languages='') {
    //alert(Cookies.get("sitelanguage"));
    if(languages==''){
      languages=Cookies.get("sitelanguage");
    }
debugger;
    const response = await fetch("https://api.voiceboat.com/api/v1/text/language-translate", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ prompt: text, language:languages })
    });
    if (!response.ok) {
        return "Translation failed";
    }
    const result = await response.json();
    console.log(result.data);
    return result.data;
}


    return (
        <>
<input type="hidden" id="hdnchatid" />
{/* //////////////////////////////////////////// chat modal ////////////////////////////////////// */}
<div className="offcanvas offcanvas-end" data-bs-scroll="true" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
      <div className="offcanvas-header bg-primary shadow-sm align-items-center dropdown">
        <h5 className="offcanvas-title" id="offcanvasExampleLabel">
          <a className="text-white text-decoration-none fw-medium" role="button" id="exampleDropdownMenu" data-bs-toggle="dropdown" aria-expanded="false" data-bs-offset="0,0">
            <span><i className="bi bi-lightning"></i></span>
            <span className="text-capitalize">Chat with admin</span>
          </a>
    
        </h5>
        <button type="button" className="btn-close btn-close-white text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        <button type="button" className="exp-but btn btn-outline-warning-2x" onClick={handleclosechat} id="CloseSidebar" value="Close" title="End Chat"><i className="fa-regular fa-square-xmark"></i></button>
      </div>
      <div className="offcanvas-body chat">
        <div className="chat-history chat-msg-box custom-scrollbar">
          <ul ref={chatContainerRef}  onScroll={handleScroll} id="chatboxshow">

          {chatmessage.map((option, index)  => {
            const isLastMessage = index === chatmessage.length - 1;
            return(<div ref={isLastMessage ? lastMessageRef : null}>

{option.messagefrom ==="user" ?(
  <>
<li className="clearfix" key={index} >
<div className="message other-message pull-right"><img className="rounded-circle float-end chat-user-img img-30" src="../assets/images/per.png" alt="" />
  <div className="message-data"><span className="message-data-time">{/*{window.getDateStringwithtime(option.messagetime)}*/}{option.messagefromname} {localizeTime(option.messagetime)}</span></div>
  {option.image==="" ? option.message : <img src={`${option.image}`} width='200' alt="Chat Image" />}
</div>
</li>
  </>
):(
<>
<li key={index}>
<div className="message my-message"><img className="rounded-circle float-start chat-user-img img-30" src="../assets/images/per.png" alt="" />
  <div className="message-data text-end"><span className="message-data-time">{/*{window.getDateStringwithtime(option.messagetime)}*/}{option.messagefromname=="ChatBot"?"ChatBot":option.messagefromname} {localizeTime(option.messagetime)}</span></div>
  {option.message}
</div>
{(chatmessage.length<=1 || index===0) && (<>
<div className="clearfix"></div> 
<a href="#" className="chatprobbtn" onClick={() => handleProblemClick('Invoice Problem')}>Invoice Problem</a>
<a href="#" className="chatprobbtn" onClick={() => handleProblemClick('Customer Problem')}>Customer Problem</a>
<a href="#" className="chatprobbtn" onClick={() => handleProblemClick('Estimate Problem')}>Estimate Problem</a>
<a href="#" className="chatprobbtn" onClick={() => handleProblemClick('Product Problem')}>Product Problem</a>
<a href="#" className="chatprobbtn" onClick={() => handleProblemClick('Payroll Problem')}>Payroll Problem</a>

</>)}   
</li>
</>     
)} </div>

);
})}

{flag === 1 && catmessages.length>=1 && (
  <li>
    <div className="message my-message">
    <img className="rounded-circle float-start chat-user-img img-30" src="../assets/images/per.png" alt="" />
      <div className="message-data text-end">
        <span className="message-data-time ">{time}</span>
      </div>
      Please select a question from below
      <div className="clearfix"></div>
      {catmessages.map((option, index) => (
     <button 
     key={index} 
     onClick={(e) => {
       e.preventDefault();
       e.stopPropagation();
       setTimeout(() => {
        sendMessageExisting(option.question);
      }, 2000);
     }} 
     className="chatprobbtn"
   >
     {option.question}
   </button>
      ))}
    </div>
  </li>
)}
{ansflag === 1  && (
  <li>
    <div className="message my-message">
     <button 
     
     onClick={(e) => {
       e.preventDefault();
       e.stopPropagation();
       setTimeout(() => {
        resolveyourIssue('yes',0);
      }, 500);
     }} 
     className="chatprobbtn"
   >
     Yes
   </button>
   <button 
     
     onClick={(e) => {
       e.preventDefault();
       e.stopPropagation();
       setTimeout(() => {
        resolveyourIssue('no',0);
      }, 500);
     }} 
     className="chatprobbtn"
   >
     No
   </button>
    
    </div>
  </li>

  
)}

{talkflag === 1  && (
  <li>
    <div className="message my-message">
     <button 
     
     onClick={(e) => {
       e.preventDefault();
       e.stopPropagation();
       setTimeout(() => {
        talkwithsupportTeam('yes');
        $("#chattxtbox").show();
      }, 500);
     }} 
     className="chatprobbtn"
   >
     Yes
   </button>
   <button 
     onClick={(e) => {
       e.preventDefault();
       e.stopPropagation();
       setTimeout(() => {
        talkwithsupportTeam('no');
      }, 500);
     }} 
     className="chatprobbtn"
   >
     No
   </button>
   <button 
     onClick={(e) => {
       e.preventDefault();
       e.stopPropagation();
       setTimeout(() => {
        talkwithsupportTeam('ticket');
      }, 500);
     }} 
     className="chatprobbtn"
   >
     Support Ticket
   </button>
    
    </div>
  </li>
)}

          </ul>
        </div>
        <div className="chat-message clearfix" id="chattxtbox">
          <div className="row">
            <div className="col-xl-12 d-flex">
              <label className="smiley-box bg-primary mb-0" htmlFor="uploadfileschat">
                <i className="fas fa-paperclip"></i>
                <input type="file" onChange={handleFileChange} id="uploadfileschat" style={{display: "none"}} name="image" accept="image/gif,image/jpeg,image/jpg,image/png" multiple="" data-original-title="upload photos" />
              </label>
              <div className="input-group text-box" ref={messagesEndRef}>
              <input type="text" className="form-control input-txt-bx" value={input} onChange={(e) => setInput(e.target.value)} onKeyDown={handleKeyPress} id="chatmessage" name="message-to-send" placeholder="Type a message.."/>
                <button className="btn btn-primary input-group-text"  onClick={handlesendmessage} type="button">Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



        
        <button type="button" className="exp-but exp-but1 btn btn-outline-primary-2x" id="maxImize" value="Expand"><i className="fa-regular fa-maximize"></i></button>

     
        </>
    )
};
export default ChatSec;